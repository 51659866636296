import { Support } from 'styled-icons/boxicons-regular'
import { Certificate } from 'styled-icons/fluentui-system-filled'
import { Calculator } from 'styled-icons/boxicons-solid'
import { GraphTrend } from 'styled-icons/foundation'

const Icons = {
  Support,
  Certificate,
  GraphTrend,
  Calculator
}

export default Icons
