import React from 'react'

import { Wrapper, ContactButton } from './styles'

export default function ContactSection() {
  return (
    <Wrapper>
      <ContactButton to="/contato">Entre em contato</ContactButton>
    </Wrapper>
  )
}
