import styled from 'styled-components'
import { Link } from 'gatsby'

export const Wrapper = styled.section`
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 160px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(283px, 1fr));
`

export const ContactButton = styled(Link)`
  color: #ffffff;
  box-shadow: 8px 8px 20px rgba(14, 123, 179, 0.9);
  padding: 1.4rem 2rem;
  background: var(--primary-color);
  border-radius: 0.6rem;
  transition: all 0.5s ease 0s;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-align: center;
  justify-self: center;
  margin: 20px 0;

  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(14, 123, 179, 0.36);
    transition: 0.5s;
    color: #ffffff;
  }
`
