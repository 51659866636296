// https://stackoverflow.com/questions/8152426/how-can-i-calculate-the-number-of-years-between-two-dates
function calculateAge(birthday) {
  const today = Date.now()
  const oneDay = 1000 * 60 * 60 * 24
  const differenceInMilliseconds = today - birthday - oneDay
  const ageInMilliseconds = new Date(differenceInMilliseconds)
  const utcYear = ageInMilliseconds.getUTCFullYear()
  const minYear = 1970
  const age = Math.abs(utcYear - minYear)
  return age
}

const content = {
  presentation: `Há mais de ${calculateAge(
    new Date(2012, 4, 31).getTime()
  )} anos no mercado, a SCONTTI desenvolve soluções que atendem aos clientes de uma forma simples e ágil, com intuito de integrar a empresa ao seu contador, facilitando a comunicação entre os mesmos.`
}

export default content
