import React from 'react'

import { Wrapper } from './styles'
import content from './content'

export default function Header() {
  return (
    <Wrapper>
      <p>{content.presentation}</p>
    </Wrapper>
  )
}
