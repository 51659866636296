const content = {
  title: 'Serviços',
  services: [
    {
      icon: 'Certificate',
      description: 'Certificado digital'
    },
    {
      icon: 'GraphTrend',
      description: 'SCONTTI ERP'
    },
    {
      icon: 'Support',
      description: 'Suporte'
    }
  ]
}

export default content
