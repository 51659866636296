import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Header from '../components/Header'
import ServicesSection from '../components/ServicesSection'
import ContactButtonSection from '../components/ContactButtonSection'

export default function IndexPage() {
  return (
    <Layout>
      <Seo title="Home" isHome />
      <Header />
      <ServicesSection />
      <ContactButtonSection />
    </Layout>
  )
}
